import React from 'react';
import * as AppContext from './AppContext';
import Layout from './components/layout';
import {navigate} from 'gatsby';
import {useOutlet} from 'reconnect.js';

function PageContainer(props) {
  return (
    <WithLogin {...props}>
      {({checked}) => (
        <Layout {...props}>{checked ? props.children : <div />}</Layout>
      )}
    </WithLogin>
  );
}

function WithLogin(props) {
  const app = React.useContext(AppContext.Context);
  const [user] = useOutlet('user');
  const [checked, setChecked] = React.useState(false);

  React.useEffect(() => {
    const EXCLUSION_LIST = [
      '/article',
      '/article/',
      '/article/editor',
      '/',
      '/dev/editor',
    ]; // dashboard 負向表列

    (async () => {
      setChecked(false);
      if (EXCLUSION_LIST.includes(props.path)) {
        // pass checking
        return setChecked(true);
      }

      let success = true;
      if (!user) {
        // try autologin
        app.actions.setLoading(true);
        try {
          await app.actions.autoLogin();
          if (props.path === '/') {
            navigate('/summary');
          }
        } catch (err) {
          // token invalid > go landing
          navigate('/');
          success = false;
        }
        app.actions.setLoading(false);
      }
      setChecked(success);
    })();
  }, [app.actions, user, props.path]);

  return props.children({checked});
}

function AppRoot(props) {
  return <AppContext.Provider>{props.children}</AppContext.Provider>;
}

export {AppRoot, PageContainer};
