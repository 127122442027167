import React from 'react';
import {navigate} from 'gatsby';
import styled from 'styled-components';
import {Apps} from '@styled-icons/material';
import SlideInPanel from './SlideInPanel';
import {useOutlet} from 'reconnect.js';
import * as AppContext from '../AppContext';
import * as Widget from './Widget';
import {ArrowBackIos} from '@styled-icons/material';
import ServiceRoutes from '../ServiceRoutes';

function NavSlideInButton(props) {
  const [user] = useOutlet('user');
  const panelRef = React.createRef();

  if (!user) {
    return null;
  }

  function canGoBack() {
    const topRoutes = ['/', '/summary'];
    if (topRoutes.indexOf(props.location?.pathname) > -1) {
      return false;
    }
    return true;
  }

  const articleService = user.services.find(
    (sv) => sv.service_name === 'jstorage',
  );

  function open() {
    panelRef.current.open(
      <div style={{padding: 20, position: 'relative'}}>
        {canGoBack() && (
          <Widget.FlexRow
            style={{marginBottom: 10, cursor: 'pointer'}}
            onClick={() => {
              navigate('/summary');
              panelRef.current.close();
            }}>
            <ArrowBackIos size={24} style={{left: -4}} />
            <div style={{flex: 1}}>Summary</div>
          </Widget.FlexRow>
        )}

        {user.services.map((service) => (
          <h2
            key={service.service_name}
            onClick={() => {
              navigate(ServiceRoutes[service.service_name].route);
              panelRef.current.close();
            }}
            style={{cursor: 'pointer'}}>
            {ServiceRoutes[service.service_name].label}
          </h2>
        ))}

        {articleService ? (
          <h2
            onClick={() => {
              navigate(ServiceRoutes['articles'].route);
              panelRef.current.close();
            }}
            style={{cursor: 'pointer'}}>
            {ServiceRoutes['articles'].label}
          </h2>
        ) : null}

        {user ? (
          <h2
            onClick={() => {
              navigate('/issuers');
              panelRef.current.close();
            }}
            style={{cursor: 'pointer'}}>
            Issuer
          </h2>
        ) : null}
      </div>,
    );
  }

  return (
    <>
      <ButtonWrapper onClick={open}>
        <Apps size={24} color="white" />
      </ButtonWrapper>

      <SlideInPanel
        zIndex={10000}
        position="left"
        getInstance={(inst) => {
          panelRef.current = inst;
        }}
      />
    </>
  );
}

const Wrapper = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
`;

const ButtonWrapper = styled.div`
  position: fixed;
  bottom: 30px;
  right: 30px;
  width: 64px;
  height: 64px;
  border-radius: 50%;
  background-color: #e50914;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  cursor: pointer;
`;

export default NavSlideInButton;
