import React from 'react';
import styled from 'styled-components';
import {navigate} from 'gatsby';
import * as Ant from 'antd';
import * as Widget from './Widget';
import {useOutlet} from 'reconnect.js';

function NavBar(props) {
  const [user] = useOutlet('user');
  const [actions] = useOutlet('actions');
  let title = 'Revtehtech Dashboard';

  if (props.location?.pathname === '/ns') {
    title = 'Notification';
  } else if (props.location?.pathname === '/jstore') {
    title = 'JSON Store';
  } else if (props.location?.pathname === '/storage') {
    title = 'Storage';
  }

  return (
    <Wrapper>
      <Widget.MaxWidthCenter maxWidth={1024} style={{padding: 20}}>
        <Widget.FlexRow>
          <div
            style={{
              color: 'black',
              fontSize: 20,
              marginRight: 15,
              fontFamily: 'VictorMonoItalic',
            }}>
            {title}
          </div>

          <Widget.FlexPlaceholder />

          <div
            style={{
              color: 'black',
              marginRight: 8,
              fontFamily: 'VictorMonoItalic',
            }}>
            {user?.username || ''}
          </div>

          {user && (
            <Ant.Button
              type="primary"
              onClick={async () => {
                await actions.logout();
                setTimeout(() => {
                  navigate('/');
                }, 200);
              }}>
              LOGOUT
            </Ant.Button>
          )}
        </Widget.FlexRow>
      </Widget.MaxWidthCenter>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: 0;
`;

export default NavBar;
