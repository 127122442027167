const ServiceRoutes = {
  revns: {
    label: 'Notifications',
    route: '/ns',
  },
  jstorage: {
    label: 'JSON Store',
    route: '/jstore',
  },
  articles: {
    label: 'Article',
    route: '/articles',
  },
  storage: {
    label: 'Storage',
    route: '/storage',
  },
};

export default ServiceRoutes;
