// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-article-service-index-js": () => import("./../../../src/templates/ArticleService/index.js" /* webpackChunkName: "component---src-templates-article-service-index-js" */),
  "component---src-templates-article-service-preview-page-js": () => import("./../../../src/templates/ArticleService/PreviewPage.js" /* webpackChunkName: "component---src-templates-article-service-preview-page-js" */),
  "component---src-templates-article-service-standalone-editor-page-js": () => import("./../../../src/templates/ArticleService/StandaloneEditorPage.js" /* webpackChunkName: "component---src-templates-article-service-standalone-editor-page-js" */),
  "component---src-templates-create-admin-user-page-js": () => import("./../../../src/templates/CreateAdminUserPage.js" /* webpackChunkName: "component---src-templates-create-admin-user-page-js" */),
  "component---src-templates-issuer-service-index-js": () => import("./../../../src/templates/IssuerService/index.js" /* webpackChunkName: "component---src-templates-issuer-service-index-js" */),
  "component---src-templates-json-store-service-index-js": () => import("./../../../src/templates/JsonStoreService/index.js" /* webpackChunkName: "component---src-templates-json-store-service-index-js" */),
  "component---src-templates-landing-page-js": () => import("./../../../src/templates/LandingPage.js" /* webpackChunkName: "component---src-templates-landing-page-js" */),
  "component---src-templates-notification-service-index-js": () => import("./../../../src/templates/NotificationService/index.js" /* webpackChunkName: "component---src-templates-notification-service-index-js" */),
  "component---src-templates-reset-password-page-js": () => import("./../../../src/templates/ResetPasswordPage.js" /* webpackChunkName: "component---src-templates-reset-password-page-js" */),
  "component---src-templates-storage-service-index-js": () => import("./../../../src/templates/StorageService/index.js" /* webpackChunkName: "component---src-templates-storage-service-index-js" */),
  "component---src-templates-summary-page-js": () => import("./../../../src/templates/SummaryPage.js" /* webpackChunkName: "component---src-templates-summary-page-js" */)
}

