import React from 'react';
import {getNewOutlet} from 'reconnect.js';
import {req, checkApiResp, onApiError} from './utils/api';
import * as Discovery from 'rev.sdk.js/Actions/Discovery';
import * as SdkConfig from 'rev.sdk.js/Config';

const constants = require('../data/config.json');
const jwt = require('jsonwebtoken');
const App = initializeApp();

function initializeApp() {
  const Loading = getNewOutlet('loading', false, {autoDelete: false});
  const User = getNewOutlet('user', null, {autoDelete: false});
  const Actions = {};
  const Theme = {
    lightBgColor: '#eee',
  };

  const _getUserToken = () => {
    const user = User.getValue();
    return user?.token;
  };

  const _getRequiredService = (name) => {
    const user = User.getValue();
    if (!user) {
      throw new Error('not login');
    }

    const service = user.services.find((s) => s.service_name === name);
    if (!service) {
      throw new Error('no such service');
    }

    return service;
  };

  const getStorageUrl = () => {
    const user = User.getValue();
    if (!user) {
      throw new Error('not login');
    }

    // it's a hack, because almost every project uses jstorage
    const service = user.services.find((s) => s.service_name === 'jstorage');
    if (service.domain.indexOf('-stg') > -1) {
      return `https://storage-stg.revtel-api.com/v4`;
    }
    return `https://storage.revtel-api.com/v4`;
  };

  const getAccessUrl = () => {
    const user = User.getValue();
    if (!user) {
      throw new Error('not login');
    }

    // it's a hack, because almost every project uses jstorage
    const service = user.services.find((s) => s.service_name === 'jstorage');
    if (service.domain.indexOf('-stg') > -1) {
      return `https://discovery-stg.revtel-api.com/v1`;
    }
    return `https://discovery.revtel-api.com/v1`;
  };

  Actions.setLoading = (loading) => Loading.update(loading);

  Actions.onApiError = onApiError;

  Actions.autoLogin = async () => {
    const token = window.localStorage.getItem('token'); // this is refresh_token
    if (!token) {
      throw new Error('no token');
    }

    // get access token
    const resp = await (
      await fetch(
        `${constants.authHost}/management/access?refresh_token=${token}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        },
      )
    ).json();

    // parse jwt
    let decodedResp = jwt.decode(resp.token);
    let client = decodedResp.aud;

    //get available services
    SdkConfig.init({
      clientId: client,
      stage: constants.stage,
    });

    let services = await Discovery.discoverServices(client, {
      updateConfig: true,
    });

    User.update({
      ...decodedResp,
      username: decodedResp.sub,
      group: decodedResp.grp,
      token: resp.token,
      services,
      client,
    });

    // TODO: verify token locally
    // https://keys.revtel-api.com/pub.json
  };

  Actions.login = async ({username, password}) => {
    const resp = await (
      await fetch(`${constants.authHost}/management/sign-in`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({username, password}),
      })
    ).json();

    let decodedResp = jwt.decode(resp.token);
    let client = decodedResp.aud;
    console.log('decoded', decodedResp);

    SdkConfig.init({
      clientId: client,
      stage: constants.stage,
    });

    let services = await Discovery.discoverServices(client, {
      updateConfig: true,
    });

    // const services = await (
    //   await fetch(`${constants.serviceHost}/service?client_id=${client}`)
    // ).json();

    window.localStorage.setItem('token', resp.refresh_token); // this might be refresh token

    User.update({
      ...decodedResp,
      username: decodedResp.sub,
      group: decodedResp.grp,
      token: resp.token,
      services,
      client,
    });
  };

  Actions.loginViaJwt = async (jwtToken) => {
    let decodedResp = jwt.decode(jwtToken);
    let client = decodedResp.aud;
    console.log('decoded', decodedResp);

    SdkConfig.init({
      clientId: client,
      stage: constants.stage,
    });

    let services = await Discovery.discoverServices(client, {
      updateConfig: true,
    });

    // const services = await (
    //   await fetch(`${constants.serviceHost}/service?client_id=${client}`)
    // ).json();

    User.update({
      username: decodedResp.sub,
      group: decodedResp.grp,
      token: jwtToken,
      services,
      client,
    });
  };

  Actions.logout = async () => {
    window.localStorage.removeItem('token');
    User.update(null);
  };

  Actions.sendPushNotification = async (payload) => {
    const service = _getRequiredService('revns');

    const url = `${service.domain}/${
      service.version
    }/notification/publish/topic/public-topic?token=${_getUserToken()}`;

    return await (
      await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      })
    ).json();
  };

  Actions.sendSms = async (payload) => {
    const service = _getRequiredService('revns');

    const url = `${service.domain}/${
      service.version
    }/sms/send?token=${_getUserToken()}`;

    return await (
      await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      })
    ).json();
  };

  Actions.fetchEmailTemplates = async () => {
    const service = _getRequiredService('revns');
    const url = `${service.domain}/${
      service.version
    }/email/template?token=${_getUserToken()}`;

    const resp = await fetch(url, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return await (await checkApiResp(resp)).json();
  };

  Actions.putEmailTemplate = async (record) => {
    const service = _getRequiredService('revns');
    const url = `${service.domain}/${
      service.version
    }/email/template/put?token=${_getUserToken()}`;

    const resp = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(record),
    });
    return await (await checkApiResp(resp)).json();
  };

  Actions.fetchEmailSenders = async () => {
    const service = _getRequiredService('revns');
    const url = `${service.domain}/${
      service.version
    }/email/sender?token=${_getUserToken()}`;

    const resp = await fetch(url, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return await (await checkApiResp(resp)).json();
  };

  Actions.putEmailSender = async (record) => {
    const service = _getRequiredService('revns');
    const url = `${service.domain}/${
      service.version
    }/email/sender/put?token=${_getUserToken()}`;

    const resp = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(record),
    });
    return await (await checkApiResp(resp)).json();
  };

  Actions.fetchCollections = async () => {
    const service = _getRequiredService('jstorage');
    const url = `${service.domain}/${
      service.version
    }/collection?token=${_getUserToken()}`;

    return req({url});
  };

  Actions.putCollection = async (collection) => {
    const service = _getRequiredService('jstorage');
    const url = `${service.domain}/${
      service.version
    }/collection/put?token=${_getUserToken()}`;

    return req({url, method: 'POST', data: collection});
  };

  Actions.fetchPermissions = async (name, data = {}) => {
    const service = _getRequiredService('jstorage');
    const url = `${service.domain}/${
      service.version
    }/permission/${name}/list?token=${_getUserToken()}`;

    return req({url});
  };

  Actions.putPermission = async (name, data) => {
    const service = _getRequiredService('jstorage');
    const url = `${service.domain}/${
      service.version
    }/permission/${name}/put?token=${_getUserToken()}`;

    return req({url, method: 'POST', data});
  };

  Actions.getUploadUrlFromFile = async (file, options = {}) => {
    const fileKey = file.name.split('.')[0];
    const fileType = file.type;
    const {acl = 'public-read'} = options;
    return await req({
      url: `${getStorageUrl()}/storage/presigned/url?client_id=${
        User.getValue().client
      }&token=${User.getValue().token}`,
      method: 'POST',
      data: {
        acl,
        'Content-Type': fileType,
        key: `${fileKey}`,
      },
    });
  };

  Actions.fetchAllUploads = async () => {
    return await req({
      url: `${getStorageUrl()}/storage/file/list?client_id=${
        User.getValue().client
      }&token=${User.getValue().token}`,
      method: 'POST',
      data: {},
    });
  };

  Actions.fetchAccessLog = async (service) => {
    let service_name = `service_${service.display_name}`;
    return await req({
      url: `${getAccessUrl()}/access?service_name=${service_name}&token=${
        User.getValue().token
      }`,
    });
  };

  Actions.resetPassword = async (data) => {
    return await req({
      url: `${constants.authHost}/management/password/reset?token=${
        User.getValue().token
      }`,
      method: 'POST',
      data,
    });
  };

  Actions.fetchIssuers = async () => {
    const url = `${constants.authHost}/issuer?token=${User.getValue().token}`;

    return await req({
      url,
    });
  };

  Actions.putIssuer = async (data) => {
    const url = `${constants.authHost}/issuer/put?token=${
      User.getValue().token
    }`;

    return await req({
      url,
      method: 'PUT',
      data,
    });
  };

  Actions.createAdminuser = async (data) => {
    const url = `${constants.authHost}/admin/register?token=${
      User.getValue().token
    }`;

    return await req({
      url,
      method: 'POST',
      data,
    });
  };

  getNewOutlet('actions', Actions, {autoDelete: false});

  return {
    Actions,
    Theme,
  };
}

export const Actions = App.Actions;
export const Theme = App.Theme;
export const Context = React.createContext();
const Dimension = getNewOutlet('dimension', {}, {autoDelete: false});
export function Provider(props) {
  const detectDimension = React.useCallback(() => {
    const nextDimension = {};
    if (typeof window !== undefined) {
      nextDimension.innerWidth = window.innerWidth;
      nextDimension.innerHeight = window.innerHeight;
      Dimension.update(nextDimension);
    }
  }, []);

  const onResize = React.useCallback(() => {
    detectDimension();
  }, [detectDimension]);

  React.useEffect(() => {
    try {
      detectDimension();
      window.addEventListener('resize', onResize);
      return () => {
        window.removeEventListener('resize', onResize);
      };
    } catch (ex) {
      console.warn('Provider:useEffect', ex);
    }
  }, [onResize, detectDimension]);

  return (
    <Context.Provider
      value={{
        actions: App.Actions,
        theme: App.Theme,
      }}>
      {props.children}
    </Context.Provider>
  );
}
