import React from 'react';
import PropTypes from 'prop-types';
import '../index.css';
import 'antd/dist/antd.less';
import NavBar from './NavBar';
import NavSlideInButton from './NavSlideInButton';
import ActivityIndicator from './ActivityIndicator';

function isStandalone(pathname) {
  const patterns = [
    /^\/article\/?$/,
    /^\/article\/editor\/?$/,
    /^\/dev\/editor\/?$/,
  ];

  for (const p of patterns) {
    if (p.test(pathname)) {
      return true;
    }
  }

  return false;
}

const Layout = (props) => {
  if (isStandalone(props.location.pathname)) {
    return <main>{props.children}</main>;
  }

  return (
    <main>
      {props.children}
      <NavBar {...props} />
      <NavSlideInButton {...props} />
      <ActivityIndicator {...props} />
    </main>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
